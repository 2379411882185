import { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const CardList: FC<Props> = ({ className = '', children }) => {
  return (
    <div data-course-list className={`${styles.container} ${className}`}>
      {children}
    </div>
  );
};

export { CardList };
