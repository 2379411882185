import { FC } from 'react';

import { NO_REPORT } from 'core/constants/app-constants';
import { QuestionMarkSVG } from '../Icons';

type Props = {
  className?: string;
  curriculumCode: 'G' | 'M';
  shortText?: boolean;
};

const GeneralStudiesLink: FC<Props> = ({ curriculumCode }) => {
  const generalStudyLink = `https://catalog.asu.edu/ug_gsr${
    curriculumCode === 'M' ? '#general_studies_maroon' : ''
  }`;

  return (
    <a target="_blank" href={generalStudyLink} rel="noreferrer">
      <QuestionMarkSVG />
    </a>
  );
};

const GeneralStudiesLabel: FC<Props> = ({
  className = '',
  curriculumCode,
  shortText = false,
}) => {
  let generalStudy = shortText ? '' : 'General Studies ';
  let gsClassName = '';

  if (curriculumCode === 'M') {
    generalStudy += 'Maroon';
    gsClassName = 'bg-maroon text-white';
  } else if (curriculumCode === 'G') {
    generalStudy += 'Gold';
    gsClassName = 'bg-gold text-dark';
  } else {
    generalStudy += NO_REPORT;
    gsClassName = 'bg-gray-500 text-white';
  }

  return (
    <strong
      className={`${className} ${gsClassName} px-3 py-1 text-center text-sm leading-none`}
    >
      {generalStudy}
    </strong>
  );
};

function parseDesignationDescription(description: string): {
  gsCode: 'G' | 'M';
  gsDesc: 'Gold' | 'Maroon';
  parsedDescription: string;
}[] {
  // Regular expression to match exactly 4 consecutive letters
  const fourLetterCodeRegex = /\b[A-Za-z]{4}\b/;
  // Regular expression to match "OR" with optional spaces around it
  const orRegex = /\s?OR\s?/;

  // Check if the description contains "OR"
  if (orRegex.test(description)) {
    const response: ReturnType<typeof parseDesignationDescription> = [];
    // Split the description by "OR"
    let parts = description.split(orRegex);

    for (let part of parts) {
      part = part.trim();

      if (fourLetterCodeRegex.test(part)) {
        response.push({
          gsCode: 'G',
          gsDesc: 'Gold',
          parsedDescription: part,
        });
      } else {
        response.push({
          gsCode: 'M',
          gsDesc: 'Maroon',
          parsedDescription: part,
        });
      }
    }

    return response;
  } else if (fourLetterCodeRegex.test(description)) {
    // If no "OR" but has 4-letter gsCode
    const parsedDescription = description.match(fourLetterCodeRegex)?.[0] || '';

    return [
      {
        gsCode: 'G',
        gsDesc: 'Gold',
        parsedDescription,
      },
    ];
  } else {
    // If no 4-letter gsCode or "OR"
    return [
      {
        gsCode: 'M',
        gsDesc: 'Maroon',
        parsedDescription: description,
      },
    ];
  }
}

export { GeneralStudiesLabel, GeneralStudiesLink, parseDesignationDescription };
