import { fetchData, postData } from '../../utils/http-utils';
import { getViewAsPayload } from '../ui/user-view-as-service';

const JWT_IDP_KEY = 'apps.asu.edu.IDP.token';
const JWT_CLAIM_IDP_KEY = 'apps.asu.edu.IDP.token.claim';
const IDP_SEARCH_LIST_KEY = 'apps.asu.edu.IDP.search-list';

async function getUserRoles() {
  try {
    const data = await requestIDPAuthToken();
    const roles = data?.role ?? [];

    return {
      roles,
    };
  } catch (err) {
    console.error('[getUserRoles]', err);
    return {
      roles: [],
    };
  }
}

function getIDPAuthToken() {
  const token = localStorage.getItem(JWT_IDP_KEY);

  return { token };
}

function getIDPClaimToken(): API.Token | null {
  const rawData = localStorage.getItem(JWT_CLAIM_IDP_KEY);
  const data = rawData ? (JSON.parse(rawData) as API.Token) : null;

  return data;
}

async function requestIDPAuthToken() {
  const URL = '/token';
  try {
    const payload = getViewAsPayload();
    const { data } = await postData<API.HttpTokenPostResponse>(URL, payload, {
      headers: {
        'Use-ServiceAuth-Token': true,
      },
    });

    localStorage.setItem(JWT_CLAIM_IDP_KEY, JSON.stringify(data));
    localStorage.setItem(JWT_IDP_KEY, data.access_token);

    return data;
  } catch (err) {
    console.error('[requestIDPAuthToken]', err);
  }
  return null;
}

async function searchUsers(searchBy: string) {
  const URL = `/api/users?search=${searchBy}`;
  const response = await fetchData<API.HttpUserSearchGetResponse>({
    url: URL,
    cache: 'force-cache',
  });
  const { data } = response;

  if (Array.isArray(data)) {
    return data;
  }

  return [data];
}

function getLastItems(historyValue: string[]) {
  const MAX_ITEMS = 9;
  return historyValue.slice(0, MAX_ITEMS);
}

function updateUserSearchHistory(searchBy: string) {
  let rawValue = localStorage.getItem(IDP_SEARCH_LIST_KEY);
  let historySearch;
  // ========================================
  try {
    historySearch = rawValue ? (JSON.parse(rawValue) as string[]) : [];
  } catch (err) {
    console.error(err);
  }
  historySearch = Array.isArray(historySearch) ? historySearch : [];

  const historyValue = Array.from(new Set([searchBy, ...historySearch]));
  const lastItems = getLastItems(historyValue);

  localStorage.setItem(IDP_SEARCH_LIST_KEY, JSON.stringify(lastItems));
}

function readUserSearchHistory() {
  try {
    const rawValue = localStorage.getItem(IDP_SEARCH_LIST_KEY);
    const historySearch = rawValue ? (JSON.parse(rawValue) as string[]) : [];

    if (Array.isArray(historySearch)) {
      return getLastItems(historySearch);
    }
  } catch (err) {
    console.error(err);
  }
  return [];
}

export {
  getUserRoles,
  getIDPClaimToken,
  getIDPAuthToken,
  requestIDPAuthToken,
  searchUsers,
  readUserSearchHistory,
  updateUserSearchHistory,
};
