import React, { FC, forwardRef, useRef, useState } from 'react';

import styles from './index.module.scss';

type CompRef = React.ForwardedRef<HTMLDivElement>;

type Props = {
  children: React.ReactNode;
  titleTag?: keyof JSX.IntrinsicElements;
  titleJustify?: 'space-between' | 'start';
  titleAlign?: 'center' | 'start' | 'end';
  className?: string;
  titleClassName?: string;
  bodyClassName?: string;
  togglePosition?: 'right' | 'left';
  [`data-{string}`]?: string;
  defaultCollapsed?: boolean;
  collapsed?: boolean;
  onCollapse?: (collapsed: boolean) => void;
} & (
  | {
      title: string;
      renderTitle?: never;
    }
  | {
      title?: never;
      renderTitle: React.ReactNode;
    }
);

const ToggleElement: FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <button
    className={styles['toggle-button']}
    data-accordion-toggle
    onClick={onClick}
  >
    <i className="fa-solid fa-chevron-down" data-type="chevron-ico" />
  </button>
);

const AccordionComp: FC<Props> = (
  {
    className,
    titleTag = 'h3',
    titleJustify,
    titleAlign,
    renderTitle,
    titleClassName,
    bodyClassName,
    title,
    defaultCollapsed = false,
    togglePosition = 'right',
    children,
    collapsed,
    onCollapse,
    ...attributes
  },
  ref,
) => {
  const [localCollapsed, setCollapsed] = useState(defaultCollapsed);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const Header = titleTag;

  const onToggleClick = () => {
    if (collapsed && onCollapse) {
      onCollapse(!collapsed);
      return;
    }

    setCollapsed(!localCollapsed);
  };

  const isCollapsed = collapsed || localCollapsed;

  return (
    <div
      ref={(node) => {
        containerRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref && Object.isExtensible(ref)) {
          ref.current = node;
        }
      }}
      className={`${className} ${styles.container} `}
      data-collapsed={isCollapsed}
      data-accordion
      {...attributes}
    >
      <div
        className={`${styles.title} ${titleClassName}`}
        data-collapsed={isCollapsed}
        style={{
          ['--title-justify' as string]: titleJustify,
          ['--title-align' as string]: titleAlign,
        }}
      >
        {togglePosition === 'left' ? (
          <>
            <ToggleElement onClick={onToggleClick} />
            {renderTitle ?? (
              <Header className={styles['heading']}>{title}</Header>
            )}
          </>
        ) : (
          <>
            {renderTitle ?? (
              <Header className={styles['heading']}>{title}</Header>
            )}
            <ToggleElement onClick={onToggleClick} />
          </>
        )}
      </div>
      <div
        ref={bodyRef}
        className={`${styles['body']} ${isCollapsed ? bodyClassName : ''}`}
        data-collapsed={isCollapsed}
        data-accordion-body
        onKeyDown={(e) => {
          if (!isCollapsed && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            return false;
          }
        }}
      >
        {children}
      </div>
    </div>
  );
};

const CompWithRef = forwardRef((props: Props, ref: CompRef) =>
  AccordionComp(props, ref),
);

export { CompWithRef as Accordion };
