import { FC, forwardRef, useCallback, useEffect, useRef } from 'react';

import { BaseButton } from '../BaseButton';
import styles from './index.module.scss';

export type DialogInstance = {
  element: HTMLDialogElement;
  showModal: HTMLDialogElement['showModal'];
  close: HTMLDialogElement['close'];
};

type ModalRef = React.ForwardedRef<DialogInstance>;

type Props = {
  className?: string;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  headerTheme?: 'dark' | 'light';
  headerStyle?: React.CSSProperties;
  showCloseXButton?: boolean;
  showStickyHeader?: boolean;
  showModal?: boolean;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  maxWidth?: React.CSSProperties['width'];
  maxHeight?: React.CSSProperties['height'];
  minWidth?: React.CSSProperties['width'];
  minHeight?: React.CSSProperties['height'];
  willChange?: React.CSSProperties['willChange'];
  headerNoPadding?: boolean;
  bodyNoPadding?: boolean;
  bodyFullHeight?: boolean;
  closeOnEscape?: boolean;
  dischargeChildOnClose?: boolean;
  onLoad?: (dialog: HTMLDialogElement | null | undefined) => void;
  onClose?: () => void;
};

const hasClickedOnBackdrop = (
  e: React.MouseEvent<HTMLDialogElement, MouseEvent>,
) => {
  const dialog = e.currentTarget;
  const dialogDimensions = dialog.getBoundingClientRect();

  if (
    e.clientX < dialogDimensions.left ||
    e.clientX > dialogDimensions.right ||
    e.clientY < dialogDimensions.top ||
    e.clientY > dialogDimensions.bottom
  ) {
    return true;
  }
  return false;
};

const Dialog: FC<Props> = (
  {
    className,
    children,
    showModal = undefined,
    title,
    headerTheme = 'light',
    headerStyle = {},
    showCloseXButton = true,
    width = 'fit-content',
    height = 'fit-content',
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    willChange,
    headerNoPadding = false,
    bodyNoPadding = false,
    showStickyHeader = false,
    bodyFullHeight = false,
    closeOnEscape = true,
    dischargeChildOnClose = false,
    onLoad,
    onClose = () => null,
  },
  forwardRef: ModalRef,
) => {
  const dialogRef = useRef<HTMLDialogElement | null>();

  const openModal = useCallback(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    // You can't open the same dialog twice.
    if (!dialog?.open) {
      dialogRef.current?.showModal();
    }
  }, []);

  const closeModal = useCallback(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    dialog.setAttribute('closing', 'true');
    dialog.addEventListener(
      'animationend',
      () => {
        dialog.removeAttribute('closing');
        dialog.close();
      },
      {
        once: true,
      },
    );
  }, []);

  useEffect(() => {
    if (showModal === undefined) return;

    const modal = dialogRef.current;
    if (showModal) {
      !modal?.open && modal?.showModal();
      onLoad?.(dialogRef.current);
    } else {
      modal?.open && closeModal();
    }
  }, [showModal, closeModal, onLoad]);

  return (
    <dialog
      autoFocus
      data-has-header={title || showCloseXButton}
      className={`${className || ''} ${styles.container}`}
      style={{
        width,
        height,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        willChange,
      }}
      ref={(node) => {
        dialogRef.current = node;

        let instance = node
          ? ({
              showModal: openModal,
              close: closeModal,
              element: node,
            } satisfies DialogInstance)
          : null;

        if (typeof forwardRef === 'function') {
          forwardRef(instance);
        } else if (forwardRef && Object.isExtensible(forwardRef)) {
          forwardRef.current = instance;
        }
      }}
      onMouseDown={(e) => {
        if ((e.target as HTMLElement).nodeName !== 'DIALOG') {
          dialogRef.current?.setAttribute('data-mouse-down', 'true');
        }
      }}
      onMouseUp={() => {
        setTimeout(() => dialogRef.current?.removeAttribute('data-mouse-down'));
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (
          dialogRef.current?.getAttribute('data-mouse-down') ||
          ['INPUT', 'BUTTON'].includes((e.target as HTMLElement).nodeName)
        )
          return;

        if (closeOnEscape && hasClickedOnBackdrop(e)) {
          closeModal();
        }
      }}
      onClose={(e) => {
        e.stopPropagation();
        if (!forwardRef) {
          onClose?.();
        }
      }}
      onCancel={
        !closeOnEscape
          ? (e) => {
              e.stopPropagation();
              e.preventDefault();
            }
          : undefined
      }
    >
      <header
        className={styles.header}
        data-modal-header
        data-is-empty={!title && !showCloseXButton}
        data-show-sticky-header={showStickyHeader}
        data-header-no-padding={headerNoPadding}
        data-header-style={headerTheme}
        style={headerStyle}
      >
        {title && <div className={styles['header-title']}>{title}</div>}
        {showCloseXButton && (
          <BaseButton onClick={closeModal} className={styles['btn-close']}>
            <i className="fa-solid fa-xmark" />
          </BaseButton>
        )}
      </header>

      <section
        className={`${styles.body} scrollbar-base`}
        data-modal-body
        data-body-full-height={bodyFullHeight}
        data-body-no-padding={bodyNoPadding}
      >
        {dischargeChildOnClose && !showModal ? null : children}
      </section>
    </dialog>
  );
};

const ModalWithRef = forwardRef((props: Props, ref: ModalRef) =>
  Dialog(props, ref),
);

export { ModalWithRef as Modal, type ModalRef };
