function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  { x, y, radius, startAngle, endAngle }: Record<string, number>,
  clockWise = true,
) {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);
  const clockWiseFlag = '1';
  const counterWiseFlag = '0';
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  const xAxisRotation = '0'; // degrees

  let sweepFlag = clockWiseFlag;
  let startPoint = start,
    endPoint = end;

  if (!clockWise) {
    sweepFlag = counterWiseFlag;
    startPoint = end;
    endPoint = start;
  }

  const d = [
    'M',
    startPoint.x,
    startPoint.y,
    'A',
    radius,
    radius,
    xAxisRotation,
    largeArcFlag,
    sweepFlag,
    endPoint.x,
    endPoint.y,
  ].join(' ');

  return { d, startArc: start, endArc: end };
}

function createArchDataset<T>({
  dataset,
  centerXY,
  radius,
  total,
}: {
  dataset: (T & {
    id?: string;
    value: number;
  })[];
  radius: number;
  centerXY: number;
  total: number;
}) {
  let prevValueDeg = 0;

  const pathDataset = dataset
    .filter((d) => d.value > 0)
    .map((data, index) => {
      const valuePercent = (100 * data.value) / total;

      let valueDeg = (valuePercent * 360) / 100;
      if (valueDeg === 360) {
        valueDeg -= 0.001;
      }

      const { d, startArc, endArc } = describeArc({
        x: centerXY,
        y: centerXY,
        radius,
        startAngle: 0 + prevValueDeg,
        endAngle: valueDeg + prevValueDeg,
      });

      prevValueDeg += valueDeg;

      const pathData = d.includes('NaN') ? '' : d;

      // Calculate midpoint of the arc for positioning the text
      const midAngle = valueDeg / 2 + (prevValueDeg - valueDeg);
      const midPoint = polarToCartesian(centerXY, centerXY, radius, midAngle);

      return {
        id: data.id || `path_${index}`,
        pathData,
        valuePercent,
        valueDeg,
        startArc,
        endArc,
        midPoint,
        ...data,
      };
    });

  return pathDataset;
}

export { createArchDataset, polarToCartesian, describeArc };
