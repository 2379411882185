// @ts-check

import { StylesConfig } from 'react-select';

const fontSize = '0.875rem';

const dropdownStyles: StylesConfig = {
  clearIndicator: (base) => {
    return {
      ...base,
      ':hover': {
        cursor: 'pointer',
      },
    };
  },
  input: (base) => {
    return {
      ...base,
      'input:focus': {
        boxShadow: 'none !important',
        paddingLeft: '0 !important',
        border: 'none !important',
        width: 'auto !important',
      },
    };
  },
  control: (base, state) => {
    return {
      ...base,
      fontSize,
      paddingLeft: 0,
      minHeight: '40px',
      borderColor: 'var(--gray-500)',
      boxShadow: 'none',
      borderRadius: 0,
      ':hover': {
        borderColor: 'var(--gray-500)',
      },
    };
  },
  multiValue: (provided, state) => {
    const color = state.isDisabled ? '#999999' : provided.color;
    const backgroundColor = state.isDisabled
      ? 'white'
      : provided.backgroundColor;
    return { ...provided, color, backgroundColor };
  },
  group: (provided) => ({
    ...provided,
    paddingBlock: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 500,
  }),
};

export { dropdownStyles };
