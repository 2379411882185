type ScrollToElementSetting = Partial<{
  delayFocus: number;
  focused: boolean;
  childFocused: boolean;
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  inline?: ScrollLogicalPosition;
}>;

function scrollTop() {
  (document.querySelector('#root') as HTMLElement).scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function scrollTopElement(selector: string, delayScroll?: number) {
  const element = document.querySelector(selector) as HTMLElement;

  if (!element) {
    console.warn(
      `[scrollTo] - Cannot find "document.querySelector('${selector}')"`,
    );
    return;
  }

  const scroller = () =>
    element.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  if (delayScroll) {
    setTimeout(scroller, delayScroll);
  } else scroller();
}

function scrollToElement(selector: string, settings?: ScrollToElementSetting) {
  const element = document.querySelector(selector) as HTMLElement;

  if (!element) {
    console.warn(
      `[ScrollIntoView] - Cannot find "document.querySelector('${selector}')"`,
    );
    return;
  }

  element?.scrollIntoView({
    behavior: settings?.behavior || 'smooth',
    block: settings?.block || 'nearest',
    inline: settings?.inline,
  });

  if (settings?.focused) {
    const timeout = settings.delayFocus || 0;
    setTimeout(() => element.focus(), timeout);
  }

  if (settings?.childFocused && element) {
    const timeout = settings.delayFocus || 800;
    const input = element.querySelector('input, textarea') as HTMLInputElement;
    input && setTimeout(() => input.focus(), timeout);
  }

  return element;
}

const scrollToClassCard = (classUId: string) => {
  setTimeout(() => {
    const movedClass = scrollToElement(`#${classUId}`, {
      inline: 'center',
    });
    movedClass?.setAttribute('data-moved', 'true');
  }, 1000);
};

const scrollToMovedClass = (sessionUId: string | null, cardId: string) => {
  setTimeout(() => {
    const sessionSelector = `[data-session="${sessionUId}"]`;
    const courseSelector = `[data-card-id="${cardId}"]`;
    // move and animate course move
    const cardSelector = sessionUId
      ? `${sessionSelector}${courseSelector}`
      : courseSelector;
    const movedClass = scrollToElement(`${cardSelector}`, {
      inline: 'center',
    });

    movedClass?.setAttribute('data-moved', 'true');
    // animate session target
    const sessionTarget = document.querySelector(sessionSelector);
    sessionTarget?.setAttribute('data-class-moved', 'true');
    setTimeout(() => sessionTarget?.removeAttribute('data-class-moved'), 1000);
  }, 1000);
};

/**
 * Create an element on the fly to check if the OS scrollbar are active
 */
function getScrollbarWidth() {
  // Create an offscreen element
  const element = document.createElement('div');
  element.style.width = '100px';
  element.style.height = '100px';
  element.style.overflow = 'scroll';
  element.style.visibility = 'hidden';
  document.body.appendChild(element);

  // Calculate the scrollbar width
  const scrollbarWidth = element.offsetWidth - element.clientWidth;

  // Remove the offscreen element
  document.body.removeChild(element);

  return scrollbarWidth;
}

/**
 * Sync all element which share an animation to start at the same time
 *
 * @param {string} animationName - The animation which trigger the start event
 * @param {string} lookupName - The animation to look up and sync
 * @param {string} elementSelector - Selector to find the element to sync
 */
function syncAnimation(
  animationName: string,
  lookupName: string,
  elementSelector: string,
) {
  if (animationName.includes(lookupName)) {
    const cards = document.body.querySelectorAll(elementSelector);

    cards.forEach((card) => {
      const animations = card.getAnimations() as CSSAnimation[];
      const elements = animations.filter((a) =>
        a.animationName.includes(lookupName),
      );

      elements.forEach((a) => {
        a.startTime = 0;
      });
    });
  }
}

export {
  scrollToClassCard,
  scrollToMovedClass,
  scrollToElement,
  scrollTopElement,
  scrollTop,
  getScrollbarWidth,
  syncAnimation,
};
