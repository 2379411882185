function pickEmplid(appState: unknown, useSearchProfile = false): string {
  const state = appState as AppState;
  const { viewAs, emplid, search } = state.userProfile;
  let currentEmplid = emplid;

  if (useSearchProfile) {
    currentEmplid = search.selectedUser?.profile.emplid || '';
  } else if (viewAs.active) {
    currentEmplid = viewAs.emplid;
  }

  return currentEmplid || '';
}

function processPermission(scopes: string[]) {
  const hasPermission = (key: string) =>
    scopes.some((value) => new RegExp(key).test(value));

  const permissions = {
    canReadPlan: hasPermission('/plan/read:self'),
    canReadAllPlan: hasPermission('/plan/read:all'),
    canEditPlan: hasPermission('/plan/write:self'),
    canEditAllPlan: hasPermission('/plan/write:all'),
  };

  return permissions;
}

const pickCurrentRoleSelector = (state: AppState) =>
  state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

export { pickEmplid, pickCurrentRoleSelector, processPermission };
