import {
  createAsyncThunk as __createAsyncThunk,
  AnyAction,
  Dispatch,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setHttpError } from '../sharedSlice';

export function dispatchThunkError(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  error: any,
  thunkFunction: { typePrefix: string },
) {
  const res = error as HTTPError;

  if (res.config?.signal?.aborted) return;

  let statusCode;

  if (res.status || res.response?.status) {
    statusCode = res.response?.status || res.status;
  }

  dispatch(
    setHttpError({
      statusCode,
      httpError: (error as HTTPError).message,
      sourceAction: thunkFunction.typePrefix,
    }),
  );
}

export const createAsyncThunk = __createAsyncThunk.withTypes<{
  state: AppState;
  dispatch: Dispatch;
  rejectValue: string;
}>();
