import { FC, memo, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';
import { computeAnimationSettings, createArchDataset } from './utils';

type Data = {
  id?: string;
  value: number;
  bgStrokeColor: string;
  textColor?: string;
};

type Props = {
  fontSize?: string;
  total: number;
  displayValue?: string | React.ReactNode;
  size: number;
  bgStrokeColor?: string;
  fillColor?: string;
  strokeWidth?: number;
  dataset: Data[];
  onHoverShowBoxShadow?: boolean;
  onHoverShowDataLabel?: boolean;
  onClick?: () => void;
  onArcClick?: (data: Data) => void;
  onArcMouseOver?: (data: Data) => void;
  onArcMouseOut?: (data: Data) => void;
};

const PieChartCom: FC<Props> = ({
  fontSize,
  size: chartSize,
  total,
  displayValue,
  bgStrokeColor = 'var(--gray-200)',
  fillColor = 'none',
  strokeWidth = (chartSize * 11) / 100,
  dataset = [],
  onHoverShowBoxShadow = false,
  onHoverShowDataLabel = false,
  onClick = undefined,
  onArcClick = undefined,
  onArcMouseOver = undefined,
  onArcMouseOut = undefined,
}) => {
  const size = chartSize - strokeWidth;
  const svgRef = useRef<SVGSVGElement>(null);
  const labelRef = useRef<SVGForeignObjectElement>(null);

  const actionProps: React.SVGProps<SVGSVGElement> = onClick
    ? {
        onClick,
        tabIndex: 0,
      }
    : {};

  const [foreignObjectX, setForeignObjectX] = useState(0);
  const [foreignObjectY, setForeignObjectY] = useState(0);

  const center = chartSize / 2;
  const centerXY = chartSize / 2;
  const radius = size / 2;

  const pathDataset = createArchDataset<Data>({
    dataset,
    centerXY,
    radius,
    total,
  });

  useEffect(() => {
    const svg = svgRef.current;

    if (svg) {
      window.queueMicrotask(() => svg?.setAttribute('data-animate', 'true'));
      computeAnimationSettings(svg);
    }

    return () => {
      svg?.setAttribute('data-animate', 'false');
    };
  }, [dataset]);

  useEffect(() => {
    if (labelRef.current) {
      const label = labelRef.current?.firstChild as HTMLElement;
      const foreignObjectX = center - label.offsetWidth / 2;
      const foreignObjectY = center - label.offsetHeight / 2;

      setForeignObjectX(foreignObjectX);
      setForeignObjectY(foreignObjectY);
    }
  }, [center, dataset]);

  return (
    <svg
      ref={svgRef}
      className={styles.container}
      width={chartSize}
      height={chartSize}
      viewBox={`0 0 ${chartSize} ${chartSize}`}
      data-on-hover-show-box-shadow={onHoverShowBoxShadow}
      data-on-hover-show-data-label={onHoverShowDataLabel}
      style={{ fontSize }}
      {...actionProps}
    >
      <g>
        <circle
          className={styles.shadow}
          data-background
          cx={centerXY}
          cy={centerXY}
          r={radius}
          fill={fillColor}
          stroke={bgStrokeColor}
          strokeWidth={strokeWidth}
        />

        {displayValue ? (
          <foreignObject
            ref={labelRef}
            x={foreignObjectX}
            y={foreignObjectY}
            overflow="visible"
          >
            {displayValue}
          </foreignObject>
        ) : (
          <text
            x={center}
            y={center}
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            <tspan>{Math.round(pathDataset[0].valuePercent)}% </tspan>
          </text>
        )}
      </g>
      <g>
        {pathDataset.map((d) => (
          <g
            key={d.id}
            data-fill={fillColor}
            data-value={String(d.valuePercent)}
          >
            <path
              className={styles.path}
              d={d.pathData}
              fill={fillColor}
              stroke={d.bgStrokeColor}
              strokeWidth={strokeWidth}
              data-value={String(d.valuePercent)}
              onClick={() => onArcClick?.(d)}
              onMouseOver={() => onArcMouseOver?.(d)}
              onMouseOut={() => onArcMouseOut?.(d)}
            />

            <text
              className={styles['range-label']}
              x={d.midPoint.x}
              y={d.midPoint.y}
              textAnchor="middle"
              alignmentBaseline="middle"
              dominantBaseline="central"
              fill={d.textColor}
              fontSize={strokeWidth * 0.55 + 'px'}
            >
              {d.value}
            </text>
          </g>
        ))}
      </g>
    </svg>
  );
};

const PieChart = memo(PieChartCom);

export { PieChart };
