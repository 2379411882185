import { useEffect } from 'react';

const docStyle = document.documentElement.style;

const useSetCssHeightProperty = ({
  cssPropName,
  refNode,
}: {
  cssPropName: string;
  refNode: React.RefObject<HTMLElement>;
}) => {
  const elementHeight = refNode.current?.clientHeight || 0;

  useEffect(() => {
    docStyle.setProperty(cssPropName, elementHeight + 'px');

    return () => {
      docStyle.setProperty(cssPropName, '0px');
    };
  }, [cssPropName, elementHeight]);

  return { elementHeight };
};

export { useSetCssHeightProperty };
